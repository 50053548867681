import { Injectable, NgZone } from '@angular/core';
import { Storage } from '@shared/services/storage.service';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { RequestType } from '../../modules/auth/components/enums/request-type.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { NotificationType } from '@shared/enums/notification-type.enum';
import { Observable, skip, tap, timer } from 'rxjs';
import { LoginType } from '@shared/enums/login-type.enum';
import { ResponseType } from '@shared/enums/response-type.enum';

export interface DeserializeData {
  message: string;
  token: string;
  login: string;
  password: string;
  host: string;
}

const FAILED_LOGIN: string = 'loginFailed';
const CONFERENCE_ADDED: string = 'ConfAddEvent';
const CONFERENCE_UPDATED: string = 'ConfUpdEvent';
const CONFERENCE_REMOVED: string = 'ImGroupRemoved';
const LOGOUT: string = 'Logout';
const MFA_FLAG_TEXT: string = 'One time code authentication required';
const MFA_TRUSTED_FLAG_TEXT: string = 'Trusted device authentication required';
const KEEP_ALIVE_INTERVAL: number = 30000;

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  invokeId = 0;
  isLogoutTriggered: boolean = false;

  webSocket$: WebSocketSubject<any>;

  constructor(
    private storage: Storage,
    private router: Router,
    private ngZone: NgZone
  ) { }

  login(host: string, login?: string, password?: string, token?: string): void {
    const loginType: LoginType = this.storage.loginType$.getValue();
    const isLoginAllowed: boolean = Boolean(host && ((loginType === LoginType.SSO && token) || (loginType === LoginType.USER_ACCOUNT && (this.storage.sessionId$.getValue() || (login && password)))));

    if (isLoginAllowed) {
      this.storage.isLoading$.next(true);
      this.webSocket$ = webSocket({
        url: `wss://${host}:7779`,
        serializer: (value: any) => value,
        deserializer: (event: MessageEvent<string>) => this.deserialize({
          host,
          login,
          password,
          token,
          message: event.data,
        }),
        closeObserver: {
          next: () => {
            if (!this.isLogoutTriggered) {
              this.storage.notificationsList$.next({
                message: 'Connection to the phone system is not available',
                type: NotificationType.ERROR,
                isWebsocketConnection: true
              });
            } else {
              this.isLogoutTriggered = false;
            }
          }
        }
      });
      const request: string = this.createRequest(RequestType.LOGIN, login, password, token);

      this.webSocket$.pipe(
        untilDestroyed(this)
      ).subscribe({
        error: (err) => {
          if (err.type === 'error') {
            this.storage.notificationsList$.next({
              message: `${err.target.readyState === WebSocket.CLOSED
                ? 'Failed to connect to the phone system. Please check your network settings or certificates.'
                : 'Phone system connection error occurred.'}`,
              type: NotificationType.ERROR
            });
          } else if (err.message) {
            this.storage.notificationsList$.next({ message: err.message, type: NotificationType.ERROR });
          }

          this.storage.isLoading$.next(false);
        },
        complete: () => console.warn('Connection closed')
      });

      this.webSocket$.next(request);
    } else {
      this.storage.isLoading$.next(false);
    }
  }

  logout(): void {
    this.storage.isLoading$.next(true);

    Office.context.roamingSettings.remove('sessionId');
    Office.context.roamingSettings.remove('trustedDeviceId');
    Office.context.roamingSettings.remove('trustedDeviceCode');
    Office.context.roamingSettings.remove('token');
    Office.context.roamingSettings.saveAsync((result: Office.AsyncResult<void>) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        this.ngZone.run(() => {
          this.isLogoutTriggered = true;

          this.storage.sessionId$.next(null);
          this.storage.trustedDeviceId$.next(null);
          this.storage.trustedDeviceCode$.next(null);
          this.storage.authToken$.next(null);

          const request: string = this.createRequest(RequestType.LOGOUT);
          this.webSocket$.next(request);
          this.webSocket$.complete();

          this.router.navigate(['login']).then(() => this.storage.isLoading$.next(false));
        });
      }
    });
  }

  keepAliveSession(): Observable<number> {
    return timer(0, KEEP_ALIVE_INTERVAL).pipe(
      skip(1),
      tap(() => this.webSocket$.next(this.createRequest(RequestType.KEEPALIVE))),
    )
  }

  private createRequest(type: RequestType, login?: string, password?: string, token?: string): string {
    const data: string = this.serializeCommonRequest(type, login, password, token);
    const header: string = this.serializeHeader(data.length);

    return `${header}${data}`;
  }

  private serializeCommonRequest(type: RequestType, login?: string, password?: string, token?: string): string {
    const sessionId: string = this.storage.sessionId$.getValue();
    const loginType: LoginType = this.storage.loginType$.getValue();
    const isLoginTypeNotSpecified: boolean = loginType === undefined || loginType === null;

    switch (type) {
      case RequestType.LOGIN:
        return `<?xml version='1.0' encoding='UTF-8'?><loginRequest type='User' platform='Outlook' version='3.0.13.0' clientType='Desktop' persist='true' abNotify='true' apiVersion='11' forced='true' loginCapab='Audio|Video|Im|911Support|WebChat|ScreenSharing|VideoConf|SchedConf|HttpFileTransfer|ConfGroup|Switchover|Mfa' mediaCapab='Voicemail|Fax|CallRec' dcmode='phone' webToken='${((isLoginTypeNotSpecified || loginType === LoginType.USER_ACCOUNT) && sessionId) || ''}' loginType='Ordinal' trustedDeviceId='${this.storage.trustedDeviceId$.getValue() || ''}'>`
          + `<userName>${login || ''}</userName>${password && !token ? `<pwd>${password}</pwd>` : ''}${sessionId && (isLoginTypeNotSpecified || loginType === LoginType.USER_ACCOUNT) ? `<webSession>${sessionId}</webSession>` : ''}${token && (isLoginTypeNotSpecified || loginType === LoginType.SSO) ? `<ssoToken><![CDATA[${token}]]></ssoToken>` : ''}`
          + '</loginRequest>';
      case RequestType.LOGOUT:
        return '<?xml version="1.0" encoding="UTF-8"?><logout></logout>';
      case RequestType.KEEPALIVE:
        return '<?xml version="1.0" encoding="UTF-8"?><keepalive/>'
      default:
        return '';
    }
  }

  private serializeHeader(dataLength: number): string {
    let header = '';
    header += String.fromCharCode(0);
    header += String.fromCharCode(0);
    const dataLen = dataLength + 8;
    const len1 = Math.floor(dataLen / 256);
    const len2 = Math.floor(dataLen - len1 * 256);
    header += String.fromCharCode(len1);
    header += String.fromCharCode(len2);
    const invokeId = this.invokeId.toString();
    const len = invokeId.length;
    if (len < 4) {
      for (let i = 0; i < 4 - len; i++) {
        header += String.fromCharCode(0x30);
      }
    }
    for (let i = 0; i < len; i++) {
      header += invokeId[i];
    }

    const headerB64 = btoa(header);
    let encodedHeader = String.fromCharCode(headerB64.length);
    encodedHeader += headerB64;

    this.invokeId++;

    if (this.invokeId === 9999) {
      this.invokeId = 0;
    }

    return encodedHeader;
  }

  private deserialize(data: DeserializeData): void {
    const document: Document = new DOMParser().parseFromString(data.message.replace(/^.*?\w+=/, ''), 'text/xml');
    const node: ChildNode = document.childNodes.item(0);
    const sessionId: string = (node as any)?.attributes?.getNamedItem('wwwUuid')?.value;
    const isForce: boolean = (node as any)?.attributes?.getNamedItem('mode')?.value === 'forced';
    const userId: string = (node as any)?.attributes?.getNamedItem('userId')?.value;
    const username: string = (node as any)?.attributes?.getNamedItem('username')?.value;
    const child: ChildNode | undefined = node.childNodes.item(0)?.childNodes?.item(0);
    const groupId: string = this.storage.conferenceInfo$.getValue()?.groupId;

    if (node.nodeName === FAILED_LOGIN) {
      this.onLoginFailed(node, sessionId, data.host, data.password, data.token);
      return;
    }

    // @ts-ignore
    if (node.nodeName === CONFERENCE_ADDED && child?.nodeName === 'confId' && (this.storage.isRequestInProgress$.getValue() || Office.context.isDialog)) {
      this.onConferenceAdd(child);
    }

    if (node.nodeName === CONFERENCE_UPDATED && child.textContent === this.storage.conferenceId$.getValue()) {
      this.onConferenceUpdate(node);
    }

    if (node.nodeName === CONFERENCE_REMOVED && child.textContent === groupId) {
      this.onConferenceRemove();
    }

    if (node.nodeName === LOGOUT && isForce) {
      this.logout();
    }

    if (username) {
      this.storage.userName$.next(username);
    }

    if (sessionId) {
      this.onLoginSuccess(sessionId, data.login, data.host, userId);
    }
  }

  private handleMultiFactorAuth(node: ChildNode, sessionId: string, host: string): void {
    const authSessionToken = (node as any)?.attributes?.getNamedItem('authSessionToken')?.value;
    const deliveryChannel = (node as any)?.attributes?.getNamedItem('otcDeliveryChannel')?.value;
    const otcLength = (node as any)?.attributes?.getNamedItem('otcLength')?.value;
    const otcResendDelay = (node as any)?.attributes?.getNamedItem('otcResendDelay')?.value;
    const trustedDeviceMode = (node as any)?.attributes?.getNamedItem('trustedDeviceMode')?.value;
    const trustedDeviceLifeTime = (node as any)?.attributes?.getNamedItem('trustedDeviceLifeTime')?.value;

    this.storage.multiFactorData$.next({
      authSessionToken,
      deliveryChannel,
      otcLength: Number(otcLength),
      otcResendDelay: Number(otcResendDelay),
      trustedDeviceMode,
      trustedDeviceLifeTime: Number(trustedDeviceLifeTime)
    });

    if (sessionId) {
      this.storage.sessionId$.next(sessionId);
      this.storage.MxIp$.next(host);
    }

    this.router.navigate(['login', 'mfa']).then(() => this.storage.isLoading$.next(false));
  }

  private onLoginSuccess(sessionId: string, login: string, host: string, userId: string): void {
    this.storage.sessionId$.next(sessionId);
    this.storage.login$.next(login);
    this.storage.MxIp$.next(host);

    // @ts-ignore
    if (Office.context.isDialog) {
      Office.context.ui.messageParent(JSON.stringify({
        host,
        sessionId,
        userId
      }));
    } else {
      Office.context.roamingSettings.set('sessionId', sessionId);
      Office.context.roamingSettings.set('login', login);
      Office.context.roamingSettings.set('host', host);
      Office.context.roamingSettings.saveAsync(() => this.ngZone.run(() => {
        if (userId) {
          this.storage.userId$.next(userId);
        }

        this.router.navigate(['conference']).then(() => this.storage.isLoading$.next(false));
      }));
    }
  }

  private onLoginFailed(node: ChildNode, sessionId: string, host: string, password?: string, token?: string): void {
    if (node.textContent === MFA_FLAG_TEXT || node.textContent === MFA_TRUSTED_FLAG_TEXT) {
      this.handleMultiFactorAuth(node, sessionId, host);
      return;
    }

    if (password) {
      this.storage.notificationsList$.next({ message: node.textContent, type: NotificationType.ERROR });
    }

    if (token) {
      const errorCode: string = (node as any)?.attributes?.getNamedItem('Code')?.value;
      const message: string = ([ResponseType.INVALID_CREDENTIALS, ResponseType.SSO_UNAVAILABLE] as string[]).includes(errorCode)
        ? 'Login failed'
        : errorCode === ResponseType.LOGIN_FAILED
          ? 'Permission denied. Please contact System Administrator'
          : node.textContent;
      this.storage.notificationsList$.next({ message: message, type: NotificationType.ERROR });
      this.storage.authToken$.next(null);
    }

    this.router.navigate(['login']).then(() => this.storage.isLoading$.next(false));
  }

  private onConferenceAdd(child: ChildNode | undefined): void {
    const host: string = this.storage.MxIp$.getValue();

    this.storage.lastCreatedConferenceHost$.next(host);
    this.storage.conferenceId$.next(child.textContent);

    // @ts-ignore
    if (Office.context.isDialog) {
      Office.context.ui.messageParent(JSON.stringify({ conferenceId: this.storage.conferenceId$.getValue() }));
    } else {
      this.storage.setCustomProperty('conferenceId', child.textContent);
      this.storage.setCustomProperty('lastCreatedConferenceHost', host);
      this.storage.saveCustomProperties();
    }

    this.storage.isRequestInProgress$.next(false);
  }

  private onConferenceUpdate(node: ChildNode): void {
    const updatedConferenceName: string = (node.childNodes.item(0) as any)?.getElementsByTagName('name').item(0).textContent;
    const startDate: number = Number((node.childNodes.item(0) as any)?.getElementsByTagName('startDate').item(0).textContent);
    const duration: number = Number((node.childNodes.item(0) as any)?.getElementsByTagName('duration').item(0).textContent) * 60;
    const deleteWhenOwnerLeave: boolean = (node.childNodes.item(0) as any)?.getElementsByTagName('delOnOwnerLeave').item(0).textContent === 'true';

    Office.context.mailbox.item.subject.setAsync(updatedConferenceName);
    Office.context.mailbox.item.start.setAsync(new Date(startDate * 1000));
    Office.context.mailbox.item.end.setAsync(new Date((startDate + duration) * 1000));

    this.storage.deleteWhenOwnerLeave$.next(deleteWhenOwnerLeave);
  }

  private onConferenceRemove(): void {
    this.storage.conferenceId$.next(null);

    Office.context.mailbox.item.body.setAsync('');
    Office.context.mailbox.item.location.setAsync('');

    this.storage.removeCustomProperty('conferenceId');
    this.storage.removeCustomProperty('conferenceInfo');
    this.storage.saveCustomProperties();
  }
}
