import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import packageJson from '../package.json';

console.warn(`Add-in version: ${packageJson.version}`);
Office.initialize = () => {
  Office.onReady(() => {
    platformBrowserDynamic().bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });
}
