import { Injectable, NgZone } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, of, throwError } from 'rxjs';
import { Storage } from '@shared/services/storage.service';
import { NotificationType } from '@shared/enums/notification-type.enum';

@Injectable()
export class HttpRequestsInterceptor implements HttpInterceptor {

  constructor(
    private storage: Storage,
    private ngZone: NgZone
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const modifiedRequest = request.clone({
      url: `https://${this.storage.MxIp$.getValue()}/newapi/${request.url}`
    });

    return next.handle(modifiedRequest).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        this.ngZone.run(() => {
          const message: string = `${errorResponse.message}. Please try again, and contact support if the issue persists`;

          this.storage.isLoading$.next(false);
          this.storage.notificationsList$.next({ message: errorResponse.error?.error?.msg || message, type: NotificationType.ERROR });
        });
        return throwError(() => of(errorResponse.error));
      })
    );
  }
}
