import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { APP_BASE_HREF, LocationChangeListener, LocationStrategy, PlatformLocation } from '@angular/common';


@Injectable()
export class CustomLocationStrategy extends LocationStrategy implements OnDestroy {
  private readonly baseHref: string = '';
  private removeListenerFns: (() => void)[] = [];

  constructor(
    private platformLocation: PlatformLocation,
    @Optional() @Inject(APP_BASE_HREF) baseHref?: string,
  ) {
    super();
    if (baseHref) {
      this.baseHref = baseHref;
    }
  }

  ngOnDestroy(): void {
    while (this.removeListenerFns.length) {
      this.removeListenerFns.pop()!();
    }
  }

  override onPopState(fn: LocationChangeListener): void {
    this.removeListenerFns.push(
      this.platformLocation.onPopState(fn),
      this.platformLocation.onHashChange(fn),
    );
  }

  override getBaseHref(): string {
    return this.baseHref;
  }

  override path(includeHash: boolean = false): string {
    const path = this.platformLocation.hash ?? '#';

    return path.length > 0 ? path.substring(1) : path;
  }

  override prepareExternalUrl(internal: string): string {
    const url = this.joinWithSlash(this.baseHref, internal);
    return url.length > 0 ? '#' + url : url;
  }

  override pushState() {}

  override replaceState() {}

  override forward(): void {
    this.platformLocation.forward();
  }

  override back(): void {
    this.platformLocation.back();
  }

  override getState(): unknown {
    return this.platformLocation.getState();
  }

  override historyGo(relativePosition: number = 0): void {
    this.platformLocation.historyGo?.(relativePosition);
  }

  private joinWithSlash(start: string, end: string): string {
    if (start.length == 0) {
      return end;
    }
    if (end.length == 0) {
      return start;
    }
    let slashes = 0;
    if (start.endsWith('/')) {
      slashes++;
    }
    if (end.startsWith('/')) {
      slashes++;
    }
    if (slashes == 2) {
      return start + end.substring(1);
    }
    if (slashes == 1) {
      return start + end;
    }
    return start + '/' + end;
  }

  private normalizeQueryParams(params: string): string {
    return params && params[0] !== '?' ? '?' + params : params;
  }
}
