import {
  ConferenceCreateInfo,
  ConferenceCreateResponse, ConferenceCreationData,
  ConferenceDetails, ConferenceMember,
  ConferenceServerInfo, ConferenceServerInfoResponse
} from '@shared/interfaces/conference.interface';
import { Storage } from '@shared/services/storage.service';
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, filter, forkJoin, map, Observable, of, Subscriber, switchMap, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import EmailAddressDetails = Office.EmailAddressDetails;
import Recurrence = Office.Recurrence;
import { NotificationType } from '@shared/enums/notification-type.enum';
import { enc as CryptoJSEnc, SHA1 } from 'crypto-js';
import { ApiService } from '@shared/services/api.service';
import { UsersResponse } from '@shared/interfaces/users.interface';

const ENCRYPTION_KEY: string = 'hdfgh14d25fr1h4tf5rghgfdh';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ConferenceHelperService {
  externalUsersAmount: number = 0;

  userName$: BehaviorSubject<string> = this.storage.userName$;

  constructor(
    private readonly storage: Storage,
    private readonly apiService: ApiService,
    private readonly ngZone: NgZone,
  ) {}

  createConference(formValue: any): void {
    this.storage.isLoading$.next(true);
    this.storage.isRequestInProgress$.next(true);

    this.getConferenceData(formValue).pipe(
      switchMap((data: ConferenceDetails) => this.apiService.createConferences(data, this.storage.sessionId$.getValue())),
      map((response: ConferenceCreateResponse) => response.conferenceInfo),
      untilDestroyed(this),
    ).subscribe((info: ConferenceCreateInfo) => {
      this.storage.setCustomProperty('conferenceInfo', JSON.stringify(info));
      this.storage.saveCustomProperties();

      this.storage.conferenceInfo$.next(info);

      this.storage.isLoading$.next(false);
    });
  }

  getEventData(): Observable<any> {
    this.externalUsersAmount = 0;

    const title$: Observable<string> = new Observable((observer: Subscriber<string>) => {
      Office.context.mailbox.item?.subject.getAsync((res: Office.AsyncResult<string>) => {
        if (res.status === Office.AsyncResultStatus.Succeeded) {
          this.ngZone.run(() => {
            observer.next(res.value);
            observer.complete();
          });
        }
      });
    });

    const members$: Observable<ConferenceMember[]> = new Observable((observer: Subscriber<ConferenceMember[]>) => {
      Office.context.mailbox.item?.requiredAttendees.getAsync((res: Office.AsyncResult<EmailAddressDetails[]>) => {
        if (res.status === Office.AsyncResultStatus.Succeeded) {
          this.ngZone.run(() => {
            const participantsList: string[] = res.value.map((item: EmailAddressDetails) => item.emailAddress.toLowerCase());
            const internalUsersList: ConferenceMember[] = this.storage.contactsList$.getValue().filter(
              (item: ConferenceMember) => participantsList.includes(item.email.toLowerCase())
            );
            this.externalUsersAmount += participantsList.length - internalUsersList.length;
            observer.next(internalUsersList);
            observer.complete();
          });
        }
      });
    });

    const optionalMembers$: Observable<ConferenceMember[]> = new Observable((observer: Subscriber<ConferenceMember[]>) => {
      Office.context.mailbox.item?.optionalAttendees.getAsync((res: Office.AsyncResult<EmailAddressDetails[]>) => {
        if (res.status === Office.AsyncResultStatus.Succeeded) {
          this.ngZone.run(() => {
            const participantsList: string[] = res.value.map((item: EmailAddressDetails) => item.emailAddress.toLowerCase());
            const internalUsersList: ConferenceMember[] = this.storage.contactsList$.getValue().filter(
              (item: ConferenceMember) => participantsList.includes(item.email.toLowerCase())
            );
            this.externalUsersAmount += participantsList.length - internalUsersList.length;
            observer.next(internalUsersList);
            observer.complete();
          });
        }
      });
    });

    const start$: Observable<number> = new Observable((observer: Subscriber<number>) => {
      Office.context.mailbox.item?.start.getAsync((res: Office.AsyncResult<Date>) => {
        if (res.status === Office.AsyncResultStatus.Succeeded) {
          this.ngZone.run(() => {
            observer.next((new Date(res.value)).getTime() / 1000);
            observer.complete();
          });
        }
      });
    });

    const end$: Observable<number> = new Observable((observer: Subscriber<number>) => {
      Office.context.mailbox.item?.end.getAsync((res: Office.AsyncResult<Date>) => {
        if (res.status === Office.AsyncResultStatus.Succeeded) {
          this.ngZone.run(() => {
            observer.next((new Date(res.value)).getTime() / 1000);
            observer.complete();
          });
        }
      });
    });

    const recurrence$: Observable<Recurrence> = new Observable((observer: Subscriber<Recurrence>) => {
      Office.context.mailbox.item?.recurrence.getAsync((res: Office.AsyncResult<Recurrence>) => {
        if (res.status === Office.AsyncResultStatus.Succeeded) {
          this.ngZone.run(() => {
            observer.next(res.value);
            observer.complete();
          });
        }
      });
    });

    return forkJoin([
      title$,
      members$,
      optionalMembers$,
      start$,
      end$,
      recurrence$
    ]);
  }

  getConferenceData(formValue: any): Observable<ConferenceDetails> {
    return this.getEventData().pipe(
      switchMap(([title, members, optionalMembers, start, end, recurrence]) => this.generateConferenceData({
        title,
        members: [...members, ...optionalMembers],
        start,
        end,
        recurrence
      }, formValue)),
      filter(Boolean)
    );
  }

  setMeetingData(serverInfo: ConferenceServerInfo | null, currentBody: string): void {
    let message: string;

    const domain: string = this.storage.MxIp$.getValue();
    const link: string = this.storage.conferenceInfo$.getValue()?.link;
    const meetingLink: string = `https://${domain}${link}`;
    const updatedBody: string = currentBody
      .replace(/(<br>[\s\S]*?)?<div .*? id=".*?custom-block.*?"[\s\S]*?<\/div>/g, '')
      .replace(/<span[\s\S]*?<br><\/span>/g, '');

    if (serverInfo) {
      this.getEventData().pipe(
        untilDestroyed(this)
      ).subscribe(([title, , , start, end]) => {
        const invitation: string = this.generateInvitation(serverInfo, title, start, end, meetingLink);

        message = `
          <div id="custom-block" style='font-size: 14px; line-height: 14px; font-weight: normal;'>
            <pre style="font-family: sans-serif !important;">${invitation}</pre>
          </div>
        `;

        Office.context.mailbox.item.body.setAsync(`${updatedBody}<br>${serverInfo ? message : ''}`, { coercionType: Office.CoercionType.Html });
      });
    } else {
      Office.context.mailbox.item.body.setAsync(updatedBody, { coercionType: Office.CoercionType.Html });
    }

    Office.context.mailbox.item.location.setAsync(serverInfo ? `${meetingLink}&source=zac` : '');
  }

  getConferenceInfoUpdateObserver(serverInfo: ConferenceServerInfo): Observable<number> {
    return new Observable((observer: Subscriber<number>) => {
      Office.context.mailbox.item.body.getAsync(Office.CoercionType.Html, (res: Office.AsyncResult<string>) => {
        if (res.status === Office.AsyncResultStatus.Succeeded) {
          this.ngZone.run(() => {
            this.setMeetingData(serverInfo, res.value)

            observer.next();
            observer.complete();
          });
        }
      });
    })
  }

  setConferenceInfo(dialogData: any, dialogRef: any): void {
    if (dialogData.conferenceId) {
      dialogRef.value.close();
      this.storage.isDialogOpened$.next(false);

      this.apiService.getConferenceServerInfo(
        this.storage.sessionId$.getValue(),
        this.storage.conferenceInfo$.getValue()?.accessId,
        this.storage.conferenceId$.getValue()
      ).pipe(
        map((response: ConferenceServerInfoResponse) => response.conferenceServerInfo),
        switchMap((serverInfo: ConferenceServerInfo) => this.getConferenceInfoUpdateObserver(serverInfo)),
        untilDestroyed(this)
      ).subscribe();
    }
  }

  loadContacts(sessionId: string): Observable<ConferenceMember[]> {
    return this.apiService.getUsers(sessionId).pipe(
      map((response: UsersResponse) => {
        return response.mxContactsList.map((user: any) => {
          if (user.email || user.email2) {
            return { email: user.email || user.email2, id: user.userId, type: 'User' }
          }

          return null;
        }).filter(Boolean)
      }),
      tap((usersList: ConferenceMember[]) => this.storage.contactsList$.next(usersList)),
      untilDestroyed(this)
    );
  }

  private generateInvitation(serverInfo: ConferenceServerInfo, title: string, start: number, end: number, meetingLink: string): string {
    const date: string = new Date(start * 1000).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
    }).replace(/ /g, '-');
    const time: string = new Date(start * 1000).toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h23',
    });
    const accessId: string = this.storage.conferenceInfo$.getValue()?.accessId;
    const timeZone: string = new Intl.DateTimeFormat('en-US', {
      timeZoneName: 'long',
    }).formatToParts(new Date()).find(part => part.type === 'timeZoneName')?.value;
    const duration: number = (end - start) / 60;

    return serverInfo.inviteBody
      .replace(/%Date%/g, date)
      .replace(/%Extension%/g, serverInfo.extension)
      .replace(/%Time%/g, time)
      .replace(/%INT_URL%/g, `<a href="${meetingLink}&source=zac">${meetingLink}&source=zac</a>`)
      .replace(/%DID%/g, serverInfo.DID)
      .replace(/%ID%/g, accessId)
      .replace(/%Timezone%/g, timeZone)
      .replace(/%Duration%/g, duration.toString())
      .replace(/%Subject%/g, title)
      .replace(/%URL%/g, `<a href="${meetingLink}">${meetingLink}</a>`);
  }

  private generateConferenceData(data: ConferenceCreationData, formValue: any): Observable<ConferenceDetails> {
    let title: string;

    if (!data.title) {
      title = `Conference with ${this.userName$.getValue()}`;
      Office.context.mailbox.item.subject.setAsync(title);
    }

    if (data?.start * 1000 < Date.now()) {
      const errorMessage: string = 'Please enter a start time and duration for your conference so that the end time is later than the current time';

      this.storage.notificationsList$.next({ message: errorMessage, type: NotificationType.ERROR });
      this.storage.isLoading$.next(false);

      this.storage.appointmentSaveEvent$.getValue()?.completed({ allowEvent: false, errorMessage });

      if (this.storage.isDialogOpened$.getValue()) {
        this.storage.dialogRef$.getValue().close();
        Office.context.mailbox.item.notificationMessages.addAsync('reminder', {
          type: Office.MailboxEnums.ItemNotificationMessageType.ErrorMessage,
          message: errorMessage
        });
      }

      return of(null);
    }

    const { password = null, webServiceType = null, deleteWhenOwnerLeave = null } = formValue;
    const conferenceId: string = this.storage.conferenceId$.getValue();
    const membersList: ConferenceMember[] = data.members.map((member: ConferenceMember) => ({ id: member.id, type: member.type }));
    const externalUsersAmount: number = this.externalUsersAmount > 5 ? this.externalUsersAmount : 5;
    const conferenceData: ConferenceDetails = {
      conferenceType: data.recurrence ? 'OngoingGroup' : 'SchedChatGroup',
      name: data.title || title,
      startDate: data.start,
      duration: (data.end - data.start) / 60,
      waitForOwner: false,
      maxMembers: membersList.length + externalUsersAmount + 1,
      members: membersList,
      ...(deleteWhenOwnerLeave !== null && { deleteWhenOwnerLeave }),
    };

    if (typeof webServiceType === 'string' && webServiceType !== 'Undefined') {
      conferenceData.webService = 'MxMeeting';
      conferenceData.webServiceType = webServiceType;
    }

    if (password) {
      conferenceData.password = this.getSHAEncryptedString(password);
    }

    if (conferenceId) {
      const conferenceInfo: ConferenceCreateInfo = this.storage.conferenceInfo$.getValue();

      conferenceData.conferenceId = conferenceId;
      conferenceData.accessId = conferenceInfo.accessId;
      conferenceData.groupId = conferenceInfo.groupId;
    }

    return of(conferenceData);
  }

  private getSHAEncryptedString(password: string): string {
    return `${SHA1(password, ENCRYPTION_KEY).toString(CryptoJSEnc.Base64)}\\n`;
  }
}
