import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ConferenceDetails,
  ConferenceCreateResponse,
  ConferenceResponse, ConferenceServerInfoResponse
} from '@shared/interfaces/conference.interface';
import { UserInfoResponse, UserProfileResponse, UsersResponse } from '@shared/interfaces/users.interface';
import {
  MultiFactorApiData,
  MultiFactorApiResponse,
  MultiFactorResendCodeResponse
} from '../../modules/auth/interfaces/multi-factor.intercace';

export interface BaseApiResponse {
  command: string;
  index: number;
  success: boolean;
  listInfo?: ListInfo;
}

export interface ListInfo {
  pagesAvailable?: number;
  recordsTotal: number;
  recordsAvailable?: number;
  recordsLimit?: number;
  recordsOffset?: number;
  recordsReturned?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) {}

  getConferenceInfo(conferenceId: string, accessId: string, sessionId: string): Observable<ConferenceResponse> {
    return this.http.get<ConferenceResponse>(
      `?command=cli_get_conference_info&conferenceId=${conferenceId}&accessId=${accessId}`,
      { headers: new HttpHeaders().set('wwwsession', sessionId) }
    );
  }

  createConferences(data: ConferenceDetails, sessionId: string): Observable<ConferenceCreateResponse> {
    return this.http.post<ConferenceCreateResponse>(
      `conferences`,
      `command=cli_add_scheduled_conference&data=${encodeURIComponent(JSON.stringify(data))}`,
      { headers: new HttpHeaders().set('wwwsession', sessionId) }
    );
  }

  updateConference(data: ConferenceDetails, sessionId: string): Observable<BaseApiResponse> {
    return this.http.post<BaseApiResponse>(
      'conferences',
      `command=cli_update_scheduled_conference&data=${encodeURIComponent(JSON.stringify(data))}`,
      { headers: new HttpHeaders().set('wwwsession', sessionId) }
    );
  }

  removeConference(data: { conferenceId: string; groupId: string }, sessionId: string): Observable<BaseApiResponse> {
    return this.http.post<BaseApiResponse>(
      'conferences',
      `command=cli_delete_scheduled_conference&data=${encodeURIComponent(JSON.stringify(data))}`,
      { headers: new HttpHeaders().set('wwwsession', sessionId) }
    );
  }

  getUserInfo(userId: string, sessionId: string): Observable<UserInfoResponse> {
    return this.http.get<UserInfoResponse>(`?command=cli_get_user_info&userId=${userId}`, { headers: new HttpHeaders().set('wwwsession', sessionId) });
  }

  getUsers(sessionId: string): Observable<UsersResponse> {
    return this.http.get<UsersResponse>(
      `?command=cli_get_mx_contacts&page=1&_chunk_size_=3000&startup=true`,
      { headers: new HttpHeaders().set('wwwsession', sessionId) }
    );
  }

  multiFactorLogin(data: MultiFactorApiData, sessionId: string): Observable<MultiFactorApiResponse> {
    return this.http.post<MultiFactorApiResponse>(
      '',
      `command=cli_login_mfa&data=${encodeURIComponent(JSON.stringify(data))}`,
      { headers: new HttpHeaders().set('wwwsession', sessionId) }
    );
  }

  resendMultiFactorCode(data: Partial<MultiFactorApiData>, sessionId: string): Observable<MultiFactorResendCodeResponse> {
    return this.http.post<MultiFactorResendCodeResponse>(
      '',
      `command=cli_otc_resend&data=${encodeURIComponent(JSON.stringify(data))}`,
      { headers: new HttpHeaders().set('wwwsession', sessionId) }
    )
  }

  getConferenceServerInfo(sessionId: string, accessId: string, conferenceId: string): Observable<ConferenceServerInfoResponse> {
    return this.http.get<ConferenceServerInfoResponse>(
      `config/user?command=cli_get_conference_server_info&accessId=${accessId}&conferenceId=${conferenceId}`,
      { headers: new HttpHeaders().set('wwwsession', sessionId) }
    );
  }
}
