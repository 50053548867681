<div class="error-notification">
  <img src="assets/icons/attention.svg" alt="attention" class="error-notification__icon">

  <div class="error-notification__dismissal" (click)="closeSnackBar()">
    <img src="assets/icons/close.svg" alt="close">
  </div>

  <div class="error-notification__block">
    <div class="error-notification__header">Error</div>
    <div class="error-notification__description" [class.grayed]="isWebsocketConnection">{{ data.message }}</div>
    <div *ngIf="isWebsocketConnection" class="error-notification__actions">
      <div class="retry" (click)="retryConnection()">Retry</div>
      <div class="relogin" (click)="goToLogin()">Relogin</div>
    </div>
  </div>
</div>
