import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Notification } from '@shared/interfaces/notification.interface';
import { AuthService } from '@shared/services/auth.service';
import { Router } from '@angular/router';
import { Storage } from '@shared/services/storage.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  isWebsocketConnection: boolean = false;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: Notification,
    private snackBarRef: MatSnackBarRef<NotificationComponent>,
    private authService: AuthService,
    private router: Router,
    private storage: Storage,
  ) {
    this.isWebsocketConnection = this.data.isWebsocketConnection;
  }

  closeSnackBar(): void {
    this.snackBarRef.dismiss();
  }

  retryConnection(): void {
    this.snackBarRef.dismiss();
    this.authService.login(
      this.storage.MxIp$.getValue(),
      this.storage.login$.getValue(),
      null,
      this.storage.authToken$.getValue()
    );

  }

  goToLogin(): void {
    this.storage.isLoading$.next(true);
    this.router.navigate(['login']).then(() => {
      this.snackBarRef.dismiss();
      setTimeout(() => this.storage.isLoading$.next(false), 500);
    });
  }
}
